import { MOT_TYPE } from '@projektionisten/mobi-template-model';

export class MotFilterItem {
	public ariaLabel: string;
	public active: boolean;
	public type: MOT_TYPE;

	constructor (ariaLabel: string, active: boolean, type: any) {
		this.ariaLabel = ariaLabel;
		this.active = active;
		this.type = type;
	}
}
