<div class="Spinner">
	<div class="MobiActivityIndicator">
		<div title="Title"></div>
		<div class="item loading-2">
			<div class="spinner">
				<div class="circle circle-1">
					<div class="circle-inner"></div>
				</div>
				<div class="circle circle-2">
					<div class="circle-inner"></div>
				</div>
			</div>
		</div>
	</div>	
</div>
