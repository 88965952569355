import { Injectable } from '@angular/core';
import { TrafficNode } from '@projektionisten/mobi-template-model';
import { environment } from '../../environments/environment';
import { environment as environmentPROD } from '../../environments/environment.production';
@Injectable({
	providedIn: 'root'
})
export class HelperService {

	/**
	 * emulates an enum object like a compiled typescript enum
	 * @param enumArray is of type of dynamic array (actually it's {any} thus {enum[]} is no type)
	 * @param inputEnum is o type enum (actually it's {any} thus {enum} is no type)
	 * @returns {{}}
	 */
	public static emulateEnum (enumArray: any, inputEnum: any): object {
		const returnObj: {} = {};
		for (const item of enumArray) {
			Object.defineProperty(returnObj, item, {
				value: inputEnum[item],
				writable: true,
				enumerable: true,
				configurable: true
			});
		}
		for (const item of enumArray) {
			Object.defineProperty(returnObj, inputEnum[item], {
				value: item,
				writable: true,
				enumerable: true,
				configurable: true
			});
		}
		return returnObj;
	}

	public static getEnum (enumIdentifier: any): any {
		return Object.keys(enumIdentifier)
			.map((key) => enumIdentifier[key])
			.filter((value) => typeof value === 'number');
	}

	/**
	 * Checks whether the trafficnode has no keys, is null or is undefined
	 * @param {TrafficNode} node
	 */
	 public static trafficNodeIsEmpty (node: TrafficNode): boolean {
		return node === null || node === undefined || Object.keys(node).length === 0
			|| (node.name === '' && node.location.latitude === 0 && node.location.longitude === 0);
	}

	public static getCorrectPortalURL (language: string = 'DE') {
		const environmentURL = environment.suedtirolmobilUrl[language];
		if (environmentURL !== undefined) {
			return environmentURL;
		} else {
			return environmentPROD.suedtirolmobilUrl['DE'];
		}
	}

}
