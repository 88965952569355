import { ApiToAppMatcher, MOT_TYPE, TRANSITION_TYPE, TRANSPORTATION_CATEGORY } from '@projektionisten/mobi-template-model';
import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class ApiToAppMatcherService implements ApiToAppMatcher {

	constructor () {
		// constructor
	}

	/**
	 * Returns the locally used mot type
	 * matched from the provided enum, because the model (and api) often
	 * uses more values than the app wants to display
	 * @param type Type from bigger enum, coming from the informationInterface
	 * @returns {MOT_TYPE | TRANSITION_TYPE}
	 */
	public matchMotToApp (type: MOT_TYPE | TRANSITION_TYPE, category: TRANSPORTATION_CATEGORY): MOT_TYPE | TRANSITION_TYPE {
		let returnValue: MOT_TYPE | TRANSITION_TYPE;
		if (category === TRANSPORTATION_CATEGORY.MOT) {
			switch (type) {
				case MOT_TYPE.TAXI:
					returnValue = MOT_TYPE.TAXI;
					break;
				case MOT_TYPE.BIKE:
					returnValue = MOT_TYPE.BIKE;
					break;
				case MOT_TYPE.BUS:
				case MOT_TYPE.BUS_CITYBUS:
				case MOT_TYPE.BUS_INTERCITY:
				case MOT_TYPE.BUS_REGIONAL:
				case MOT_TYPE.BUS_EXPRESS:
					returnValue = MOT_TYPE.BUS;
					break;
				case MOT_TYPE.TRAIN:
				case MOT_TYPE.TRAIN_RE:
				case MOT_TYPE.TRAIN_ICE:
				case MOT_TYPE.TRAIN_IC:
				case MOT_TYPE.TRAIN_URBAN:
				case MOT_TYPE.LIGHTRAIL:
				case MOT_TYPE.TRAM:
				case MOT_TYPE.SUBWAY:
					returnValue = MOT_TYPE.TRAIN;
					break;
				case MOT_TYPE.HAILEDSHAREDTAXI:
					returnValue = MOT_TYPE.HAILEDSHAREDTAXI;
					break;
				case MOT_TYPE.FERRY:
					returnValue = MOT_TYPE.FERRY;
					break;
				case MOT_TYPE.CABLEWAY:
					returnValue = MOT_TYPE.CABLEWAY;
					break;
				default:
					returnValue = MOT_TYPE.ANY;
					break;
			}
		} else {
			// this app uses all of the transition types
			returnValue = type;
		}

		return returnValue;
	}

	/**
	 * Returns the locally used setting for the maximum distance to the stop
	 * matched from the provided enum, because the model (and api) often
	 * uses more values than the app wants to display
	 * @param type Type from bigger enum, coming from the informationInterface
	 * @returns {MAX_DISTANCE}
	 */
	/* public matchMaxDistanceSettingToApp (type: MAX_DISTANCE): MAX_DISTANCE {
	 return type;
	 }*/

	/**
	 * Returns the locally used setting for the maximum number of times
	 * the user wants to change MOTs
	 * matched from the provided enum, because the model (and api) often
	 * uses more values than the app wants to display
	 * @param type Type from bigger enum, coming from the informationInterface
	 * @returns {MAX_CHANGE}
	 */
	/* public matchMaxChangeSettingToApp (type: MAX_CHANGE): MAX_CHANGE {
	 return type;
	 }*/

	/**
	 * Returns the locally used setting for the appr. walking speed
	 * of the user
	 * matched from the provided enum, because the model (and api) often
	 * uses more values than the app wants to display
	 * @param type Type from bigger enum, coming from the informationInterface
	 * @returns {WALKING_SPEED}
	 */
	/* public matchWalkingSpeedSettingToApp (type: WALKING_SPEED): WALKING_SPEED {
	 return type;
	 }*/

	/**
	 * Returns the locally used setting for level of impairment of the user
	 * matched from the provided enum, because the model (and api) often
	 * uses more values than the app wants to display
	 * @param type Type from bigger enum, coming from the informationInterface
	 * @returns {MOBILITY_IMPAIRMENT}
	 */
	/* public matchMobilityImpairmentSettingToApp (type: MOBILITY_IMPAIRMENT): MOBILITY_IMPAIRMENT {
	 return type;
	 }*/

}
