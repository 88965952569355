<div class="AppWrapper" [attr.lang]="languageCode">
	<a class="AppWrapper__logo" [attr.href]="getCorrectPortalURL()" [attr.aria-label]="'APP_TITLE.LINK' | translate:{link: getCorrectPortalURL()}" target="_blank" rel="noopener noreferrer"></a>
	<mobi-traffic-node-finder [renderPriority]="2" [input]="originName" [placeholder]="'INPUT_FIELD.STARTPOINT' | translate" (selectedTrafficNode)="selectedTrafficNode('origin', $event)" (locationError)="displayLocationError($event)"></mobi-traffic-node-finder>
	<mobi-traffic-node-finder [renderPriority]="1" [input]="destinationName" [placeholder]="'INPUT_FIELD.ENDPOINT' | translate" (selectedTrafficNode)="selectedTrafficNode('destination', $event)" (locationError)="displayLocationError($event)"></mobi-traffic-node-finder>
	<div class="AppWrapper__seperator"></div>
	<div translate>DATETIME.HEADER</div>
	<mobi-date-time-strip [selectedDateTime]="selectedDateTime" (dateTimeHandler)="selectedDateTimeChanged($event)"></mobi-date-time-strip>
	<mobi-radio-group *ngIf="showArrivalOrDepartureOption" [name]="'connections-timetables-toggle'" [items]="connectionTimetableOptions" (clickHandler)="arrivalOrDepartureSelected($event)"></mobi-radio-group>
	<ng-container *ngIf="showMotOptions">
		<div translate>MOT_FILTER.HEADER</div>
		<mobi-mot-filter [motFilters]="motFilters" (motFilterClickHandler)="motFilterClicked($event)"></mobi-mot-filter>
	</ng-container>
	<mobi-button-cta [text]="buttonText" [isEnabled]="buttonEnabled" [ariaDescription]="buttonDescription" (buttonClickHandler)="search()"></mobi-button-cta>

	<modal class="LocationNotFoundPopUp" #locationNotFoundPopUp [animationTiming]="500" [contentTop]="'auto'" [mode]="'popup'" [hideCloseButton]="true" [backdropParentSelector]="'.AppWrapper'" [backdropColor]="'#000000'" [backdropOpacity]="0.3">
		<modal-content class="LocationNotFoundPopUpContent">
			<div #locationNotFound class="LocationNotFound">
				<button (click)="locationNotFoundPopUp.close()" [attr.aria-label]="'GEOLOCATION.ARIA_BUTTON_CLOSE' | translate" type="button" class="LocationNotFound__close icon-close"></button>
				<div class="LocationNotFoundBody">
					<div aria-hidden="true" class="LocationNotFoundBody__icon" [ngClass]="locationError === GEOLOCATION_ERRORS.GEOLOCATION_NOT_AVAILABLE ? 'LocationDisabledIcon' : 'AdressNotFoundIcon'"></div>
					<div class="LocationNotFoundBody__text" role="text" *ngIf="locationError === GEOLOCATION_ERRORS.NO_RESULTS" translate>
						GEOLOCATION.LOCATION_NOT_FOUND_BODY
					</div>
					<div class="LocationNotFoundBody__text" role="text" *ngIf="locationError === GEOLOCATION_ERRORS.GEOLOCATION_NOT_AVAILABLE" translate>
						GEOLOCATION.ENABLE_LOCATION_BODY
					</div>
				</div>
				<button class="LocationNotFound__button font-bold" type="button" (click)="locationNotFoundPopUp.close()" translate>GEOLOCATION.BUTTON_OK</button>
			</div>
		</modal-content>
	</modal>
</div>
