import { Highlightable } from '@angular/cdk/a11y';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TrafficNode } from '@projektionisten/mobi-template-model';

@Component({
	selector: 'mobi-base-list-item',
	templateUrl: './base-list-item.component.html',
	styleUrls: ['./base-list-item.component.scss']
})
export class BaseListItemComponent implements Highlightable {

	@Input() public ariaId: string = '';
	public isSelected: boolean;

	setActiveStyles (): void {
		this.isSelected = true;
	}
	setInactiveStyles (): void {
		this.isSelected = false;
	}
	itemClicked () {
		// handle click on item here
	}
}
