import { Component, OnInit, Input, Output, ChangeDetectionStrategy, EventEmitter } from '@angular/core';
import * as dayjs from 'dayjs';

@Component({
	selector: 'mobi-date-time-strip',
	templateUrl: './date-time-strip.component.html',
	styleUrls: ['./date-time-strip.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateTimeStripComponent implements OnInit {

	@Input() public selectedDateTime: Date;
	@Output() public dateTimeHandler: EventEmitter<Date>;

	public currentStringDate: string;
	public currentStringTime: string;
	public selectedDate: string;
	public selectedTime: string;

	private lastInput: string;

	constructor () {
		this.dateTimeHandler = new EventEmitter();
	}

	public ngOnInit (): void {
		if ((document.getElementById('datePicker') as HTMLInputElement).type === 'text') {
			this.currentStringDate = dayjs(this.selectedDateTime).format('DD.MM.YYYY');
		} else {
			this.currentStringDate = dayjs(this.selectedDateTime).format('YYYY-MM-DD');
		}
		this.currentStringTime = dayjs(this.selectedDateTime).format('HH:mm');
		this.lastInput = this.currentStringDate;
		this.selectedDate = dayjs(this.selectedDateTime).format('DD.MM.YYYY');;
		this.selectedTime = this.currentStringTime;
	}

	public onDateInputChange (userInput: any): void{
		this.selectedDate = '';

		/*
		 * Used in Safari when input Date changes to input text
		 * Autoformatting user input to DD.MM.YYYY
		 */
		if (userInput.type === 'text') {
			const input = userInput.value;
			const values = input.split('.').map((value: string) => value.replace(/\D/g, ''));
			if (values[0]) {
				values[0] = this.checkDateValue(values[0], 31);
			}
			if (values[1]) {
				values[1] = this.checkDateValue(values[1], 12);
			}
			let output: string[];
			if (this.lastInput.length < userInput.value.length) {
				output = values.map((value: string, index: number) => value.length === 2 && index < 2 ? value + '.' : value);
				userInput.value = output.join('').substr(0, 10);
			} else {
				userInput.value = userInput.value;
			}
			const cleanDate = userInput.value.replace(/[-. /]+/g, '');
			if (cleanDate.length > 7) {
				this.selectedDate = userInput.value;
				this.returnValidDateTime();
			}
			this.lastInput = userInput.value;
		} else {
			this.selectedDate = dayjs(userInput.value).format('DD.MM.YYYY');
			this.returnValidDateTime();
		}
	}

	public checkDateValue (inputString: string, maxStringLength: number): string {
		if (inputString.charAt(0) !== '0' || inputString === '00') {
			let inputNum = parseInt(inputString);
			if (isNaN(inputNum) || inputNum <= 0 || inputNum > maxStringLength) inputNum = 1;
			inputString = inputNum > parseInt(maxStringLength.toString().charAt(0)) && inputNum.toString().length === 1 ?
				'0' + inputNum : inputNum.toString();
		};
		return inputString;
	}

	public onTimeInputChange (userInput: any): void {
		this.selectedTime = '';

		/*
		 * Used in Safari when input time changes to input text
		 * Autoformatting user input to HH:mm
		 */
		if (userInput.type === 'text') {
			const input = userInput.value;
			const values = input.split(':').map((value: string) => value.replace(/\D/g, ''));
			if (values[0]) {
				values[0] = this.checkTimeValue(values[0], 23);
			}
			if (values[1]) {
				values[1] = this.checkTimeValue(values[1], 59);
			}
			let output: string[];
			if (this.lastInput.length < userInput.value.length) {
				output = values.map((value: string, index: number) => value.length === 2 && index < 2 ? value + ':' : value);
				userInput.value = output.join('').substr(0, 5);
			} else {
				userInput.value = userInput.value;
			}
			const cleanTime = userInput.value.replace(/[: /]+/g, '');
			if (cleanTime.length > 3) {
				this.selectedTime = userInput.value;
				this.returnValidDateTime();
			}
			this.lastInput = userInput.value;
		} else {
			this.selectedTime = userInput.value;
			this.returnValidDateTime();
		}
	}

	public checkTimeValue (inputString: string, maxStringLength: number): string {
		if (inputString.charAt(0) !== '0') {
			let inputNum = parseInt(inputString);
			if (isNaN(inputNum) || inputNum <= 0 || inputNum > maxStringLength) {
				inputNum = 1;
			}
			inputString = inputNum > parseInt(maxStringLength.toString().charAt(0)) && inputNum.toString().length === 1 ?
				'0' + inputNum : inputNum.toString();
		};
		return inputString;
	}

	public returnValidDateTime (): void {
		const date = this.selectedDate.split('.');
		const year = parseInt(date[2]);
		const month = parseInt(date[1]) - 1;
		const day = parseInt(date[0]);
		const time = this.selectedTime.split(':');
		const hour = parseInt(time[0]);
		const minute = parseInt(time[1]);
		this.selectedDateTime = new Date (year, month, day, hour, minute);
		this.dateTimeHandler.emit(this.selectedDateTime);
	}
}
