import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Location, TrafficNode } from '@projektionisten/mobi-template-model';
import { EMPTY, of, Subject } from 'rxjs';
import { catchError, debounceTime, map, switchMap, tap } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api/api.service';
import { GeolocationService } from 'src/app/services/geolocation.service';

export enum GEOLOCATION_ERRORS {
	NO_RESULTS,
	GEOLOCATION_NOT_AVAILABLE
}

@Component({
	selector: 'mobi-traffic-node-finder',
	templateUrl: './traffic-node-finder.component.html',
	styleUrls: ['./traffic-node-finder.component.scss']
})
export class TrafficNodeFinderComponent implements OnInit {

	@Input() public input: string = '';
	@Input() public placeholder: string;
	@Input() public renderPriority: number = 0;
	@Output() public selectedTrafficNode: EventEmitter<TrafficNode> = new EventEmitter();
	@Output() public locationError: EventEmitter<GEOLOCATION_ERRORS> = new EventEmitter();

	public listOfTrafficNodes: TrafficNode[] = [];
	public inputIsFocused = false;
	public highlightedTrafficNode: string;
	public resultError: string;

	private searchText$: Subject<string> = new Subject<string>();

	constructor (private apiService: ApiService,
		private geolocationService: GeolocationService) {
	}

	ngOnInit (): void {
		this.searchText$.pipe(
			debounceTime(1000),
			switchMap((input: string) => {
				if (input.length >= 3) {
					const apiCall = this.apiService.getTrafficNodesForStringInput({input: input});
					let resultList = [];
					let resultError;
					return apiCall.pipe(
						map((nodes: TrafficNode[]) => {
							if (nodes.length !== 0) {
								resultList = [...resultList, ...nodes];
							} else {
								resultError = 'SUGGESTIONS_LIST.ERRORS.ERROR_NO_RESULTS';
							}
						}),
						tap(() => {
							if (resultList.length !== 0) {
								this.listOfTrafficNodes = resultList;
								this.resultError = undefined;
							} else {
								this.resultError = 'SUGGESTIONS_LIST.ERRORS.ERROR_NO_RESULTS';
							}
						}),
						catchError((error: any) => {
							console.error(error);
							if (error[0]?.code === '-8020') {
								this.resultError = 'SUGGESTIONS_LIST.ERRORS.ERROR_NO_RESULTS';
							} else {
								this.resultError = 'SUGGESTIONS_LIST.ERRORS.GENERAL_SERVER_ERROR';
							}
							return of(error);
						})
					);
				}
				return EMPTY;
			})).subscribe();
	}

	public searchTrafficNodesForInput (input: string) {
		this.input = input;
		if (input === '') {
			this.selectedTrafficNode.emit(TrafficNode.factory({}));
		} else if (input.length >= 3) {
			this.listOfTrafficNodes = undefined;
			this.resultError = undefined;
		} else {
			this.listOfTrafficNodes = [];
			this.resultError = undefined;
		}
		this.searchText$.next(input);
	}

	public inputFocused () {
		this.inputIsFocused = true;
	}

	public inputBlurred () {
		this.inputIsFocused = false;
		this.listOfTrafficNodes = [];
		this.resultError = undefined;
	}

	public nodeSelected (node: TrafficNode) {
		this.selectedTrafficNode.emit(node);
	}

	public setHighlightedTrafficNode (event) {
		this.highlightedTrafficNode = event;
	}

	public getTrafficNodeForLocation () {
		this.geolocationService.requestLocationPermission().subscribe((permissionGranted) => {
			if (permissionGranted) {
				this.inputIsFocused = true;
				this.listOfTrafficNodes = undefined;
				this.resultError = undefined;
				this.geolocationService.getLocation().subscribe({
					next: (location) => {
						const pointLocation = {
							location: Location.factory({
								longitude: location.longitude,
								latitude: location.latitude
							})
						};
						this.apiService.getTrafficNodesForStringInput(pointLocation).subscribe((result) => {
							this.inputIsFocused = false;
							this.listOfTrafficNodes = [];
							this.resultError = undefined;

							if (result[0] !== undefined) {
								this.nodeSelected(result[0]);
							} else {
								this.locationError.emit(GEOLOCATION_ERRORS.NO_RESULTS);
							}
						});
					},
					error: (error) => {
						this.inputIsFocused = false;
						this.listOfTrafficNodes = [];
						this.resultError = undefined;
						this.locationError.emit(GEOLOCATION_ERRORS.GEOLOCATION_NOT_AVAILABLE);
					}
				});
			} else {
				this.inputIsFocused = false;
				this.listOfTrafficNodes = [];
				this.resultError = undefined;
				this.locationError.emit(GEOLOCATION_ERRORS.GEOLOCATION_NOT_AVAILABLE);
			}
		});
	}
}
