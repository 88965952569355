import { Environment } from './environment.model';

export const environment: Environment = {
	isLocal: false,
	isDevelopment: false,
	isStaging: false,
	isProduction: true,
	name: 'production',
	suedtirolmobilUrl: {
		IT: 'https://www.altoadigemobilita.info/it/',
		DE: 'https://www.suedtirolmobil.info/de/',
		EN: 'https://www.suedtirolmobil.info/en/',
		LAD: 'https://www.suedtirolmubilta.info/lad/'
	}
};
