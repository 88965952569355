import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
	selector: 'mobi-button-cta',
	templateUrl: './button-cta.component.html',
	styleUrls: ['./button-cta.component.scss']
})
export class ButtonCtaComponent {

	@Input() public text: string;
	@Input() public isEnabled: boolean;
	@Input() public ariaDescription: string;

	@Output() public buttonClickHandler: EventEmitter<void> = new EventEmitter<void>();

	buttonClicked () {
		this.buttonClickHandler.emit();
	}
}
