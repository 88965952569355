import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Location, TrafficNode, TRAFFICNODE_TYPE } from '@projektionisten/mobi-template-model';
import { ApiService } from 'src/app/services/api/api.service';
import { GeolocationService } from 'src/app/services/geolocation.service';
import { BaseListItemComponent } from '../base-list-item.component';

@Component({
	selector: 'mobi-list-item-position',
	providers: [{ provide: BaseListItemComponent, useExisting: ListItemPositionComponent }],
	templateUrl: './list-item-position.component.html',
	styleUrls: ['./list-item-position.component.scss']
})
export class ListItemPositionComponent extends BaseListItemComponent implements OnInit {

	@Input() public text: string;
	@Output() public locationSearchSelected: EventEmitter<void>;

	public TRAFFICNODE_TYPE: typeof TRAFFICNODE_TYPE = TRAFFICNODE_TYPE;
	public locationEnabled: boolean = false;

	constructor (private geolocationService: GeolocationService) {
		super();
		this.locationSearchSelected = new EventEmitter();
	}

	ngOnInit (): void {
		this.geolocationService.requestLocationPermission().subscribe((permissionGranted) => {
			this.locationEnabled = permissionGranted;
		});
	}

	itemClicked () {
		this.locationSearchSelected.emit();
	}
}
