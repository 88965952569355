<div class="RadioGroup" role="radiogroup" [attr.aria-labelledby]="name">
	<div class="RadioButton" (keydown)="onEnter($event)"
		role="radio" tabindex="0"
		[attr.aria-checked]="item.selected"
		[attr.aria-label]="(item.title) | translate"
		(click)="itemClicked(item, $event)"
		*ngFor="let item of items">
		<div class="RadioButton__disc">
			<div *ngIf="item.selected" class="RadioButton__disc--selected"></div>
		</div>
		<div class="RadioButton__text" [ngClass]="{ 'RadioButton__text--selected' : item.selected }"> {{ item.title | translate }} </div>
	</div>
</div>