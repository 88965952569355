import { Injectable, Inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { EFASTAInformationInterface } from '@projektionisten/information-interface-efa-sta';
import { StaOpenDataHubInformationInterface } from '@projektionisten/sta-open-data-hub-information-interface';
import {
	InformationInterface,
	TrafficNode,
	AppSettings,
	InformationInterfaceHttpClient,
	LANGUAGE
} from '@projektionisten/mobi-template-model';
import { forkJoin, from, Observable, throwError } from 'rxjs';
import { ApiToAppMatcherService } from './api-to-app-matcher.service';
import { STAPOIInterface } from '@projektionisten/sta-open-data-hub-information-interface/dist/interfaces/StaPoiInterface';
import { catchError, map, mergeMap } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class ApiService implements Pick<
InformationInterface,
'getTrafficNodesForStringInput'
>, Pick<
STAPOIInterface,
'prefetchPOIList'> {

	private informationInterfaces: any;
	private language: LANGUAGE = LANGUAGE.DE;

	constructor (
		private appSettings: AppSettings,
		private apiToAppMatcher: ApiToAppMatcherService,
		@Inject('InformationInterfaceHttpClient') private http: InformationInterfaceHttpClient,
		@Inject('ApiServiceUrlPrefix') urlPrefix: string) {
		// TODO set language and platform from settings
		const PLATFORM = 'app';
		this.informationInterfaces = {
			efaSta: new EFASTAInformationInterface(this.appSettings, this.apiToAppMatcher, this.http, urlPrefix, PLATFORM === 'app'),
			poiopendata: new StaOpenDataHubInformationInterface(
				this.appSettings, this.apiToAppMatcher, this.http, this.language, 'SuedtirolMobilWebwidget' )
		};

		this.prefetchPOIList().subscribe({
			error: () => {
				console.error('Error fetching POI list on language change');
			}
		});

	}

	// todo rename, because we now also resolve location bases searches with it
	public getTrafficNodesForStringInput (params: any): Observable<TrafficNode[]> {
		params = { ...params, language: this.language };

		const apisToQuery: InformationInterface[] = [];
		apisToQuery.push(this.informationInterfaces.efaSta);
		apisToQuery.push(this.informationInterfaces.poiopendata);

		return forkJoin(apisToQuery.map((api) => api.getTrafficNodesForStringInput(params))).pipe(
			map((results: Array<Array<TrafficNode>>) => {
				let resultList = [];
				for (const result of results) {
					resultList = [...resultList, ...result];
				}
				return resultList as Array<TrafficNode>;
			}),
			catchError((error) => throwError(error)));
	}

	public prefetchPOIList (): Observable<void> {
		return this.informationInterfaces.poiopendata.prefetchPOIList({ language: this.language });
	}

	public setLanguage (lan: LANGUAGE) {
		this.language = lan;
	}
}
