import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'mobi-spinner',
	templateUrl: './spinner.component.html',
	styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit {

	constructor () {
		// empty
	 }

	ngOnInit (): void {
		// empty
	}

}
