import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
// import zone to make sure it is in the bundle and the app can run on non angular sites
import 'zone.js';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.isProduction) {
  enableProdMode();
}

console.log('suedtirolmobil web widget environment is:', environment.name);

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
