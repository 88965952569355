import { Highlightable } from '@angular/cdk/a11y';
import { Component, Input, OnInit } from '@angular/core';
import { UseExistingWebDriver } from 'protractor/built/driverProviders';
import { BaseListItemComponent } from '../base-list-item.component';

@Component({
	selector: 'mobi-list-item-header',
	templateUrl: './list-item-header.component.html',
	styleUrls: ['./list-item-header.component.scss']
})
export class ListItemHeaderComponent {
	@Input() public title: string;
}
