import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'mobi-input-field',
	templateUrl: './input-field.component.html',
	styleUrls: ['./input-field.component.scss']
})
export class InputFieldComponent {

	@Input() public userInput: string = '';
	@Input() public placeholderText: string = '';
	@Input() public clearButtonAriaText: string = '';
	@Input() public highlightedTrafficNode: string = '';
	@Input() public listId: string = '';
	@Output() public inputChanged: EventEmitter<String>;
	@Output() public inputFocused: EventEmitter<void>;
	@Output() public inputBlured: EventEmitter<void>;

	constructor () {
		this.inputChanged = new EventEmitter();
		this.inputFocused = new EventEmitter();
		this.inputBlured = new EventEmitter();
	}

	public updateUserInput () {
		this.inputChanged.emit(this.userInput);
	}

	public clearInput () {
		this.userInput = '';
		this.inputChanged.emit(this.userInput);
	}
}
