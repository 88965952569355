<div id="suggestionsList" class="SuggestionsList" role="listbox" [ngStyle]="{'z-index': renderPriority}">
	<div #scrollContainer class="SuggestionsListScrollContainer" *ngIf="errorMessage === undefined && listOfNodeResults !== undefined">
		<mobi-list-item-position [ariaId]="'list-position'" [text]="'SUGGESTIONS_LIST.CURRENT_LOCATION' | translate" (locationSearchSelected)="useLocation.emit()"></mobi-list-item-position>
		<mobi-list-item-header *ngIf="(listOfNodeResults | trafficNodeType: TRAFFICNODE_TYPE.STOP).length !== 0" [title]="'SUGGESTIONS_LIST.STOP' | translate"></mobi-list-item-header>
		<mobi-list-item [ariaId]="'list-stop-' + i" *ngFor="let stop of listOfNodeResults | trafficNodeType: TRAFFICNODE_TYPE.STOP; index as i" [trafficNode]="stop" (trafficNodeSelected)="nodeSelected($event)"></mobi-list-item>
		<mobi-list-item-header *ngIf="(listOfNodeResults | trafficNodeType: TRAFFICNODE_TYPE.ADDRESS).length !== 0" [title]="'SUGGESTIONS_LIST.ADDRESS' | translate"></mobi-list-item-header>
		<mobi-list-item [ariaId]="'list-address-' + i" *ngFor="let address of listOfNodeResults | trafficNodeType: TRAFFICNODE_TYPE.ADDRESS; index as i" [trafficNode]="address" (trafficNodeSelected)="nodeSelected($event)"></mobi-list-item>
		<mobi-list-item-header *ngIf="(listOfNodeResults | trafficNodeType: TRAFFICNODE_TYPE.POI).length !== 0" [title]="'SUGGESTIONS_LIST.POI' | translate"></mobi-list-item-header>
		<mobi-list-item [ariaId]="'list-poi-' + i" *ngFor="let address of listOfNodeResults | trafficNodeType: TRAFFICNODE_TYPE.POI; index as i" [trafficNode]="address" (trafficNodeSelected)="nodeSelected($event)"></mobi-list-item>	
	</div>
	<div *ngIf="errorMessage !== undefined && listOfNodeResults === undefined" class="SuggestionsListErrorContainer">
		<div class="SuggestionsListErrorContainer__icon"></div>
		<div class="SuggestionsListErrorContainer__text"> {{ errorMessage }} </div>
	</div>
	<div *ngIf="errorMessage === undefined && listOfNodeResults === undefined" class="SuggestionsListLoadingContainer">
		<mobi-spinner></mobi-spinner>
		<div class="SuggestionsListLoadingContainer__text" translate>SPINNER.LOADING</div>
	</div>
</div>