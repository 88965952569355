import { Injectable } from '@angular/core';
import { HelperService } from 'src/app/services/helper.service';
import {
	AppSettings,
	ERROR_CODE,
	LANGUAGE,
	LINE_SELECTION,
	MAP_PROJECTION,
	MAX_CHANGE,
	MAX_DISTANCE,
	MOBILITY_IMPAIRMENT,
	MOT_TYPE,
	ROUTE_TYPE,
	WALKING_SPEED
} from '@projektionisten/mobi-template-model';
import { TRANSITION_TYPE, OUTPUT_FORMAT } from '@projektionisten/mobi-template-model/';
import { MultiModalityPreference } from '@projektionisten/mobi-template-model/';

@Injectable({
	providedIn: 'root'
})
/**
 * specific app settings
 *
 * fill the first param of {this.helper.emulateEnum()} with all enum values you need in this concrete form of mobi-template
 * fill the second param of {this.helper.emulateEnum()} with the enum you want to emulate
 * -> this will return a object which looks like an enum array
 */
export class AppSettingsService implements AppSettings {
	public readonly MOT_TYPE: object;
	public readonly TRANSITION_TYPE: object;
	public readonly MAX_DISTANCE: object;
	public readonly MAX_CHANGE: object;
	public readonly WALKING_SPEED: object;
	public readonly LINE_SELECTION: object;
	public readonly ROUTE_TYPE: object;
	public readonly ERROR_CODE: object;
	public readonly MOBILITY_IMPAIRMENT: object;
	public readonly MULTI_MODALITY: MultiModalityPreference[];
	public readonly MAP_PROJECTION: MAP_PROJECTION;
	public readonly OUTPUT_FORMAT: OUTPUT_FORMAT;
	public readonly VENDOR_CONSTANTS: any;
	public readonly WHAT3WORDS: boolean;

	public LANGUAGE: LANGUAGE;

	constructor () {

		this.VENDOR_CONSTANTS = {
		};

		this.MOT_TYPE = HelperService.emulateEnum([
			MOT_TYPE.TRAIN,
			MOT_TYPE.BUS,
			MOT_TYPE.CABLEWAY
			// MOT_TYPE.BIKE,
			// MOT_TYPE.CAR
		], MOT_TYPE);

		this.TRANSITION_TYPE = HelperService.emulateEnum([
			TRANSITION_TYPE.ANY,
			TRANSITION_TYPE.WALKING,
			TRANSITION_TYPE.CHANGE_VEHICLES,
			TRANSITION_TYPE.STAY_IN_VEHICLE,
			TRANSITION_TYPE.STAIRS_UP,
			TRANSITION_TYPE.STAIRS_DOWN,
			TRANSITION_TYPE.ELEVATOR_UP,
			TRANSITION_TYPE.ELEVATOR_DOWN,
			TRANSITION_TYPE.ESCALATOR_UP,
			TRANSITION_TYPE.ESCALATOR_DOWN
		], TRANSITION_TYPE);

		this.MAX_DISTANCE = HelperService.emulateEnum([
			MAX_DISTANCE.ANY,
			MAX_DISTANCE.DISTANCEFIVEMIN,
			MAX_DISTANCE.DISTANCETENMIN,
			MAX_DISTANCE.DISTANCEFIFTEENMIN,
			MAX_DISTANCE.DISTANCETWENTYMIN,
			MAX_DISTANCE.DISTANCETHIRTYMIN
		], MAX_DISTANCE);

		this.MAX_CHANGE = HelperService.emulateEnum([
			MAX_CHANGE.ANY,
			MAX_CHANGE.TWO,
			MAX_CHANGE.ONE,
			MAX_CHANGE.NONE
		], MAX_CHANGE);

		this.WALKING_SPEED = HelperService.emulateEnum([
			WALKING_SPEED.VERYSLOW,
			WALKING_SPEED.SLOW,
			WALKING_SPEED.NORMAL,
			WALKING_SPEED.FAST,
			WALKING_SPEED.VERYFAST
		], WALKING_SPEED);

		this.LINE_SELECTION = HelperService.emulateEnum([
			LINE_SELECTION.ANY,
			LINE_SELECTION.NOINTERCITYEXPRESS,
			LINE_SELECTION.LOCALTRANSPORT
		], LINE_SELECTION);

		this.MOBILITY_IMPAIRMENT = HelperService.emulateEnum([
			MOBILITY_IMPAIRMENT.NOSTAIRS,
			MOBILITY_IMPAIRMENT.NOELEVATORS,
			MOBILITY_IMPAIRMENT.BIKE_TAKEALONG
		], MOBILITY_IMPAIRMENT);

		this.ROUTE_TYPE = HelperService.emulateEnum([
			ROUTE_TYPE.LEAST_TIME,
			ROUTE_TYPE.LEAST_CHANGES,
			ROUTE_TYPE.LEAST_WALKING
		], ROUTE_TYPE);

		this.MULTI_MODALITY = [];

		this.MAP_PROJECTION = MAP_PROJECTION.WGS84;
		this.OUTPUT_FORMAT = OUTPUT_FORMAT.JSON;
		this.WHAT3WORDS = false;

		this.LANGUAGE = LANGUAGE.DE;

		this.ERROR_CODE = HelperService.emulateEnum([
			ERROR_CODE.GENERAL_SERVER_ERROR,
			ERROR_CODE.CONNECTIONS_ERROR_NO_RESULTS,
			ERROR_CODE.TIMETABLES_ERROR_NO_RESULTS,
			ERROR_CODE.TRAFFICNODE_ERROR_NO_RESULTS,
			ERROR_CODE.DATE_TIME_ERROR
		], ERROR_CODE);
	}
}
