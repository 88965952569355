import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SpinnerComponent } from './spinner/spinner.component';
import { TrafficNodeTypePipe } from './pipes/traffic-node-type.pipe';
@NgModule({
	imports: [ CommonModule ],
	declarations: [ TrafficNodeTypePipe, SpinnerComponent ],
	exports: [ TrafficNodeTypePipe, CommonModule, FormsModule, SpinnerComponent ]
})
export class SharedModule {
	// empty
}
