import { Injectable } from '@angular/core';
import { Location, MAP_PROJECTION } from '@projektionisten/mobi-template-model';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class GeolocationService {

	constructor () {
		// empty
	}

	public requestLocationPermission (): Observable<boolean> {
		return new Observable((observer) => {
			if (navigator.permissions !== undefined) {
				navigator.permissions.query({
					name: 'geolocation'
				}).then((status) => {
					if (status.state === 'prompt') {
						status.onchange = (change: any) => {
							if (change.target.state === 'granted') {
								observer.next(true);
							} else {
								observer.next(false);
							}
							observer.complete();
						};
						navigator.geolocation.getCurrentPosition(() => {
							if (!observer.closed) {
								observer.next(true);
								observer.complete();
							}
						}, (error) => {
							if (!observer.closed) {
								observer.next(false);
								observer.complete();
							}
						});
					} else if (status.state === 'granted') {
						observer.next(true);
						observer.complete();
					} else {
						observer.next(false);
						observer.complete();
					}
				});
			} else {
				navigator.geolocation.getCurrentPosition(() => {
					if (!observer.closed) {
						observer.next(true);
						observer.complete();
					}
				}, (error) => {
					if (!observer.closed) {
						observer.next(false);
						observer.complete();
					}
				});
			}
		});
	}

	/**
	 * This method provides limited location support via navigator interface when debugging in browser.
	 * @param showErrorModals Determines if an error modal should be displayed
	 */
	 public getLocation (): Observable<Location> {
		return new Observable((observer) => {
			const watchRef = navigator.geolocation.watchPosition(
				(position: any) => {
					observer.next(Location.factory({
						latitude: position.coords.latitude,
						longitude: position.coords.longitude,
						projection: MAP_PROJECTION.WGS84
					}));
					observer.complete();
					navigator.geolocation.clearWatch(watchRef);
				},
				(error: any) => {
					/*
					if (error.code === error.PERMISSION_DENIED) {
						observer.error(GEOLOCATION_ERRORS.NO_AUTHORIZATION);
					} else if (error.code === error.POSITION_UNAVAILABLE) {
						observer.error(GEOLOCATION_ERRORS.GEOLOCATION_NOT_AVAILABLE);
					}
					observer.error(GEOLOCATION_ERRORS.NO_RESULTS);
					navigator.geolocation.clearWatch(watchRef);*/
				},
				{
					enableHighAccuracy: true,
					maximumAge: 0
				}
			);
		});
	}
}

