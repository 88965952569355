import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RadioGroupOption } from './radio-group.model';

@Component({
	selector: 'mobi-radio-group',
	templateUrl: './radio-group.component.html',
	styleUrls: ['./radio-group.component.scss']
})
export class RadioGroupComponent {

	@Input() public name: string;
	@Input() public items: RadioGroupOption[];
	@Output() public clickHandler: EventEmitter<RadioGroupOption>;

	constructor (
		private changeDetectorRef: ChangeDetectorRef) {
		this.clickHandler = new EventEmitter();
	}

	/**
	 * emits event that the item was clicked if not already selected
	 */
	public itemClicked (item: RadioGroupOption, event: any) {
		if (!item.selected) {
			this.clickHandler.emit(item);
			for (const type of this.items) {
				type.selected = false;
				item.selected = true;
			}
		}
		event.target.blur();
		this.changeDetectorRef.detectChanges();
	}

	public onEnter (event: any){
		if (event.keyCode === 13) {
			event.preventDefault();
			event.target.click();
			event.target.blur();
		}
	}

}
