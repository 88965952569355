import { Pipe, PipeTransform } from '@angular/core';
import { TrafficNode, TRAFFICNODE_TYPE } from '@projektionisten/mobi-template-model';

@Pipe({
	name: 'trafficNodeType'
})

export class TrafficNodeTypePipe implements PipeTransform {
	public transform (items: TrafficNode[], type: TRAFFICNODE_TYPE): any[] {
		return items.filter((node) => node.type === type);
	}
}
