import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AngularHttpClient } from '@projektionisten/http-client-angular';
import { AppSettings } from '@projektionisten/mobi-template-model';
import { AppSettingsService } from 'src/app/services/api/app-settings.service';
import { AppComponent } from './app.component';
import { DateTimeStripComponent } from './components/date-time-strip/date-time-strip.component';
import { RadioGroupComponent } from './components/radio-group/radio-group.component';
import { TranslateModule } from '@ngx-translate/core';
import { InputFieldComponent } from './components/input-field/input-field.component';
import { FormsModule } from '@angular/forms';
import { ListItemTrafficNodeComponent } from './components/list-items/base-list-item/list-item-trafficNode/list-item-trafficNode.component';
import { SuggestionsListComponent } from './components/suggestions-list/suggestions-list.component';
import { ListItemHeaderComponent } from './components/list-items/base-list-item/list-item-header/list-item-header.component';
import { SharedModule } from './components/shared/sharedComponents.module';
import { MotFilterComponent } from './components/mot-filter/mot-filter.component';
import { ButtonCtaComponent } from './components/button-cta/button-cta.component';
import { TrafficNodeFinderComponent } from './components/traffic-node-finder/traffic-node-finder.component';
import { BaseListItemComponent } from './components/list-items/base-list-item/base-list-item.component';
import { ListItemPositionComponent } from './components/list-items/base-list-item/list-item-position/list-item-position.component';
import { ModalModule } from '@projektionisten/ngx-modal';
@NgModule({
	declarations: [
		AppComponent,
		RadioGroupComponent,
		InputFieldComponent,
		ListItemTrafficNodeComponent,
		SuggestionsListComponent,
		ListItemHeaderComponent,
		DateTimeStripComponent,
		MotFilterComponent,
		ButtonCtaComponent,
		TrafficNodeFinderComponent,
		BaseListItemComponent,
		ListItemPositionComponent
	],
	imports: [
		BrowserModule,
		HttpClientModule,
		FormsModule,
		SharedModule,
		TranslateModule.forRoot({
			defaultLanguage: 'DE'
		}),
		ModalModule
	],
	providers: [
		{
			provide: AppSettings,
			useClass: AppSettingsService
		},
		{
			provide: 'InformationInterfaceHttpClient',
			useClass: AngularHttpClient
		},
		{
			provide: 'ApiServiceUrlPrefix',
			useValue: ''
		}
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
