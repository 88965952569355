import { Environment } from './environment.model';

export const environment: Environment = {
	isLocal: false,
	isDevelopment: true,
	isStaging: false,
	isProduction: false,
	name: 'development',
	suedtirolmobilUrl: {
		IT: 'https://sta.projektionisten.de/it/',
		DE: 'https://sta.projektionisten.de/de/',
		EN: 'https://sta.projektionisten.de/en/',
		LAD: 'https://sta.projektionisten.de/lad/'
	}
};
