import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LANGUAGE, TrafficNode, TRAFFICNODE_TYPE } from '@projektionisten/mobi-template-model';
import * as dayjs from 'dayjs';
import { RadioGroupOption } from './components/radio-group/radio-group.model';
import { ApiService } from './services/api/api.service';
import { MOT_TYPE } from '@projektionisten/mobi-template-model';
import { HelperService } from './services/helper.service';
import { AppSettingsService } from './services/api/app-settings.service';
import { MotFilterItem } from './components/mot-filter/mot-filter.model';
import * as DE_FILE from '../assets/i18n/DE.json';
import * as EN_FILE from '../assets/i18n/EN.json';
import * as LAD_FILE from '../assets/i18n/LAD.json';
import * as IT_FILE from '../assets/i18n/IT.json';
import { ModalComponent } from '@projektionisten/ngx-modal';
import { GEOLOCATION_ERRORS } from './components/traffic-node-finder/traffic-node-finder.component';

@Component({
	// eslint-disable-next-line @angular-eslint/component-selector
	selector: 'express-planner',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

	@ViewChild('locationNotFoundPopUp', { static: false })
	public locationNotFoundPopUp: ModalComponent;

	// input values from parameters
	public origin: string;
	public originName: string;
	public originType: TRAFFICNODE_TYPE;
	public destination: string;
	public destinationName: string;
	public destinationType: TRAFFICNODE_TYPE;
	public showArrivalOrDepartureOption: boolean;
	public showMotOptions: boolean;

	public buttonText: string;
	public buttonEnabled: boolean;
	public buttonDescription: string;
	public connectionTimetableOptions: RadioGroupOption[] = [];
	public listOfTrafficNodes: TrafficNode[] = [];
	public selectedDateTime: Date;
	public motFilters: MotFilterItem[] = [];
	public locationError: GEOLOCATION_ERRORS;
	public GEOLOCATION_ERRORS: typeof GEOLOCATION_ERRORS = GEOLOCATION_ERRORS;
	public HelperService: HelperService = HelperService;
	public languageCode: string = 'de';

	private timeIsArrival: boolean = false;

	constructor (
		public translateService: TranslateService,
		private apiService: ApiService,
		private appSettingsService: AppSettingsService,
		private element: ElementRef
	) {
		translateService.setTranslation('DE', (DE_FILE as any).default);
		translateService.setTranslation('EN', (EN_FILE as any).default);
		translateService.setTranslation('IT', (IT_FILE as any).default);
		translateService.setTranslation('LAD', (LAD_FILE as any).default);

		this.connectionTimetableOptions.push({
			title: 'RADIO_BUTTONS.BUTTON_DEPARTURE_TIME',
			selected: true,
			type: 'DEPARTURE'
		});

		this.connectionTimetableOptions.push({
			title: 'RADIO_BUTTONS.BUTTON_ARRIVAL_TIME',
			selected: false,
			type: 'ARRIVAL'
		});

		const availableMots = HelperService.getEnum(this.appSettingsService.MOT_TYPE) as Array<MOT_TYPE>;
		availableMots.forEach((motType: MOT_TYPE) => {
			this.motFilters.push(new MotFilterItem('MOT_FILTER.MOT_TYPES.' + MOT_TYPE[motType].toUpperCase(), true, motType));
		});

		this.buttonText = 'BUTTON.TEXT';
		this.buttonEnabled = true;
		this.buttonDescription = 'BUTTON.ARIATEXT';
	}

	public ngOnInit () {
		this.selectedDateTime = new Date();

		this.origin = (this.element.nativeElement as HTMLElement).getAttribute('origin');
		this.originName = (this.element.nativeElement as HTMLElement).getAttribute('originName');
		const originTypeString = Number((this.element.nativeElement as HTMLElement).getAttribute('originType'));
		this.originType = Number(originTypeString) in TRAFFICNODE_TYPE ? Number(originTypeString) : TRAFFICNODE_TYPE.STOP;

		this.destination = (this.element.nativeElement as HTMLElement).getAttribute('destination');
		this.destinationName = (this.element.nativeElement as HTMLElement).getAttribute('destinationName');
		const destinationTypeString = Number((this.element.nativeElement as HTMLElement).getAttribute('destinationType'));
		this.destinationType = Number(destinationTypeString) in TRAFFICNODE_TYPE ? Number(destinationTypeString) : TRAFFICNODE_TYPE.STOP;

		const arrivalOrDeparture = (this.element.nativeElement as HTMLElement).getAttribute('arrivalOrDeparture');
		this.showArrivalOrDepartureOption = arrivalOrDeparture !== null && arrivalOrDeparture !== 'false';
		const motOptions = (this.element.nativeElement as HTMLElement).getAttribute('motOptions');
		this.showMotOptions = motOptions !== null && motOptions !== 'false';

		const language = (this.element.nativeElement as HTMLElement).getAttribute('language');
		this.translateService.use(language !== null ? LANGUAGE[LANGUAGE[language.toUpperCase()]] : LANGUAGE[LANGUAGE['DE']]);
		this.apiService.setLanguage(language !== null ? LANGUAGE[language.toUpperCase()] : LANGUAGE['DE']);

		if (language.toLowerCase() === null) {
			this.languageCode = 'de';
		} else if (language.toLowerCase() === 'lad') {
			this.languageCode = 'lld';
		} else {
			this.languageCode = language.toLowerCase();
		}
	}

	public arrivalOrDepartureSelected (option: RadioGroupOption) {
		this.timeIsArrival = option.type === 'ARRIVAL';
	}

	public motFilterClicked (clickedItem: MotFilterItem): void {
		// Change active value of mot filter item
		const motFilterItem = this.motFilters.find((item) => item.type === clickedItem.type);
		motFilterItem.active = !motFilterItem.active;
	}

	public search (): void {
		const suedtirolmobilUrl = HelperService.getCorrectPortalURL(this.translateService.currentLang);

		window.open(`${suedtirolmobilUrl}?arrival=${this.timeIsArrival}
		&date=${dayjs(this.selectedDateTime).format('YYYY-MM-DD')}
		&time=${dayjs(this.selectedDateTime).format('HHmm')}
		&mots=[${this.motFilters.filter((mf) => mf.active).map((mf) => mf.type)}]
		${this.origin ? '&originId=' + this.origin : ''}
		${this.originType ? '&originType=' + this.originType : ''}
		${this.destination ? '&destinationId=' + this.destination : ''}
		${this.destinationType ? '&destinationType=' + this.destinationType : ''}`, '_blank');
	}

	public inputFieldFocused (): void {
		console.log('Input Focused');
	}

	public inputFieldBlured (): void {
		console.log('Input Blured');
	}

	public selectedTrafficNode (input: 'origin' | 'destination', node: TrafficNode): void {
		if (input === 'origin') {
			this.origin = node ? node.id : undefined;
			this.originName = !HelperService.trafficNodeIsEmpty(node) ? node.city + ', ' + node.name : undefined;
			this.originType = node.type;
		} else if (input === 'destination') {
			this.destination = node ? node.id : undefined;
			this.destinationName = !HelperService.trafficNodeIsEmpty(node) ? node.city + ', ' + node.name : undefined;
			this.destinationType = node.type;
		}
	}

	public selectedDateTimeChanged (newValue: Date) {
		this.selectedDateTime = newValue;
	}

	public displayLocationError (errorType: GEOLOCATION_ERRORS) {
		this.locationError = errorType;
		this.locationNotFoundPopUp.open();
		// sets the initial focus of screenreaders on content and not navbar
		const element = document.getElementsByClassName('LocationNotFound').item(0) as HTMLElement;
		if (element !== null && element !== undefined) {
			element.setAttribute('tabindex', '-1');
			element.focus();
		}
	}

	public getCorrectPortalURL () {
		return HelperService.getCorrectPortalURL(this.translateService.currentLang);
	}
}
