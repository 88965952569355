import { Component, ElementRef, EventEmitter, HostListener, Input, Output, QueryList, ViewChild, ViewChildren, AfterViewInit } from '@angular/core';
import { TrafficNode, TRAFFICNODE_TYPE } from '@projektionisten/mobi-template-model';
import { ActiveDescendantKeyManager } from '@angular/cdk/a11y';
import { BaseListItemComponent } from '../list-items/base-list-item/base-list-item.component';
@Component({
	selector: 'mobi-suggestions-list',
	templateUrl: './suggestions-list.component.html',
	styleUrls: ['./suggestions-list.component.scss']
})
export class SuggestionsListComponent implements AfterViewInit {

	@Input() public listOfNodeResults: TrafficNode[];
	@Input() public errorMessage: string;
	@Input() public renderPriority: number = 0;
	@Output() public resultSelected: EventEmitter<TrafficNode>;
	@Output() public highlightedTrafficNode: EventEmitter<string>;
	@Output() public useLocation: EventEmitter<TrafficNode>;

	@ViewChildren(BaseListItemComponent) public items: QueryList<BaseListItemComponent>;
	public TRAFFICNODE_TYPE: any = TRAFFICNODE_TYPE;

	@ViewChild('scrollContainer') private container: ElementRef;
	private keyManager: ActiveDescendantKeyManager<BaseListItemComponent>;

	@HostListener('document:keydown', ['$event']) onKeydown (event) {
		if (this.items.length > 0 && this.keyManager !== undefined) {
			this.keyManager.onKeydown(event);
			if (this.keyManager.activeItem !== null) {
				if (event.code === 'ArrowDown' || event.code === 'ArrowUp' ) {
					const focusedId = this.keyManager.activeItem.ariaId;
					this.highlightedTrafficNode.emit(focusedId);
					const listItem = document.getElementById(focusedId);
					const topPos = listItem.offsetTop;
					// subtract the height of the first heading so that we can scroll all the way up
					this.container.nativeElement.scrollTop = topPos - 28;
				}
				else if (event.code === 'Enter') {
					this.keyManager.activeItem.itemClicked();
					(document.activeElement as HTMLElement).blur();
				}
			}
		}
	}

	constructor () {
		this.resultSelected = new EventEmitter();
		this.highlightedTrafficNode = new EventEmitter();
		this.useLocation = new EventEmitter();
	 }

	ngAfterViewInit (): void {
		this.keyManager = new ActiveDescendantKeyManager(this.items);
	}
	/**
	 * Click event of the result entries in the list of nodes
	 * @param node The clicked node
	 */
	 public nodeSelected (node) {
		this.resultSelected.emit(node);
	}
}
