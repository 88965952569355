import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TrafficNode, TRAFFICNODE_TYPE } from '@projektionisten/mobi-template-model';
import { Highlightable } from '@angular/cdk/a11y/key-manager/activedescendant-key-manager.js';
import { BaseListItemComponent } from '../base-list-item.component';

@Component({
	selector: 'mobi-list-item',
	providers: [{ provide: BaseListItemComponent, useExisting: ListItemTrafficNodeComponent }],
	templateUrl: './list-item-trafficNode.component.html',
	styleUrls: ['./list-item-trafficNode.component.scss']
})
export class ListItemTrafficNodeComponent extends BaseListItemComponent {

	@Input() public trafficNode: TrafficNode;
	@Output() public trafficNodeSelected: EventEmitter<TrafficNode>;

	public TRAFFICNODE_TYPE: typeof TRAFFICNODE_TYPE = TRAFFICNODE_TYPE;

	constructor () {
		super();
		this.trafficNodeSelected = new EventEmitter<TrafficNode>();
	}
	setActiveStyles (): void {
		this.isSelected = true;
	}
	setInactiveStyles (): void {
		this.isSelected = false;
	}
	itemClicked () {
		this.trafficNodeSelected.emit(this.trafficNode);
	}
}
