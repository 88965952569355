import { Component, Output, EventEmitter, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { MOT_TYPE } from '@projektionisten/mobi-template-model';
import { MotFilterItem } from './mot-filter.model';

@Component({
	selector: 'mobi-mot-filter',
	templateUrl: './mot-filter.component.html',
	styleUrls: ['./mot-filter.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class MotFilterComponent {

	@Input() public motFilters: MotFilterItem[] = [];
	@Output() public motFilterClickHandler: EventEmitter<MotFilterItem> = new EventEmitter();

	public MOT_TYPE: typeof MOT_TYPE;

	constructor (
		private changeDetectorRef: ChangeDetectorRef) {
		this.MOT_TYPE = MOT_TYPE;
	}

	public toggleMot (item: MotFilterItem): void {
		this.motFilterClickHandler.emit(item);
		this.changeDetectorRef.detectChanges();
	}
}
